import React from 'react';
import PropTypes from 'prop-types';
import { useExitRamp, Button } from '@lxlabs/change-ad-components';
import styled from 'styled-components';

const Style = styled.a`
  color: inherit;
  font-family: inherit;
  text-decoration: underline;
  font-weight: 900;
  font-size: inherit;
  background-color: inherit;
  border: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
`;

const ExternalLink = ({
  children, href, button, text, spacing, cyData,
}) => {
  const { openExitRamp } = useExitRamp();

  return (
    button
      ? (
        <Button
          onClick={() => openExitRamp(href)}
          text={text}
          spacing={spacing}
          cyData={cyData}
        />
      )
      : <Style as="button" onClick={() => openExitRamp(href)}>{children}</Style>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  button: PropTypes.bool.isRequired,
  spacing: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }).isRequired,
  cyData: PropTypes.string.isRequired,
};

ExternalLink.defaultProps = {

};

export default ExternalLink;
